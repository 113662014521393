<template>
  <div>
    <h3 class="title">Selecione o horário desejado (formato em 24 horas)</h3>
    <span class="subtitle-2">
      Obs: Os horários abaixo são baseados no seu horário local.
    </span>
    <div class="my-5">
      <v-virtual-scroll
        v-if="hasTimes"
        :items="slotTimes"
        :item-height="70"
        :height="500"
        class="items-border-bottom-grey"
      >
        <template #default="{ item }">
          <v-list-item two-line @click="selectTime(item)">
            <v-list-item-avatar>
              <v-icon>alarm</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.startAt | formatTime }}h as
                {{ item.endAt | formatTime }}h
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <template v-else>
        <empty-list
          text="Nenhum horário encontrado para este terapeuta nesta data."
        />
      </template>
    </div>
    <stepper-buttons />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Time",
  components: {
    StepperButtons: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/StepperButtons"
      ),
  },
  computed: {
    ...mapState("appointments/patient", ["slotTimes"]),
    hasTimes() {
      return this.slotTimes && this.slotTimes.length > 0;
    },
  },
  methods: {
    selectTime({ startAt, endAt, appointmentId }) {
      this.$store.dispatch("appointments/patient/selectTime", {
        startAt,
        endAt,
        appointmentId,
      });
    },
  },
};
</script>

<style></style>
